
import { defineComponent } from "vue";
import FormGenerator from "@/components/forms/form-generator.vue"; // @ is an alias to /src
import {
  emailValidator,
  phoneValidator,
  requiredValidator,
} from "../../validators";
import { RestResources } from "../../rest-resource";
const ApiResources = RestResources();

export default defineComponent({
  name: "GetQuote",
  components: {
    FormGenerator,
  },
  data() {
    return {
      schema: [
        {
          fieldType: "InputField",
          placeholder: "Enter First Name*",
          label: "",
          name: "firstname",
          type: "text",
          value: "",
          class: "form-group col-md-6 mb-2 mt-3 text-secondary",
          defaultValue: "",
          validators: [
            { validator: requiredValidator, message: "First Name is required" },
          ],
        },
        {
          fieldType: "InputField",
          placeholder: "Enter Last Name",
          label: "",
          name: "lastname",
          type: "text",
          value: "",
          class: "form-group col-md-6 mb-2 mt-3 text-secondary",
          defaultValue: "",
        },
        {
          fieldType: "InputField",
          placeholder: "Enter Phone Number*",
          label: "",
          name: "contact_number",
          type: "text",
          value: "",
          class: "form-group col-md-6 mb-2 mt-3 text-secondary",
          defaultValue: "",
          validators: [
            {
              validator: requiredValidator,
              message: "Phone number is required",
            },
            {
              validator: phoneValidator,
              message: "Please enter valid phone number",
            },
          ],
        },
        {
          fieldType: "InputField",
          placeholder: "Enter Email",
          label: "",
          name: "email",
          type: "email",
          value: "",
          class: "form-group col-md-6 mb-2 mt-3 text-secondary",
          defaultValue: "",
          validators: [
            { validator: emailValidator, message: "Please enter valid email" },
          ],
        },
        {
          fieldType: "TextareaField",
          placeholder: "Message",
          label: "",
          name: "message",
          type: "text",
          value: "",
          rows: 5,
          class: "form-group col-md-12 mb-2 mt-3 text-secondary",
          defaultValue: "",
        },
      ],
      submitButton: {
        class: "btn submit-btn mt-5 mb-5",
        value: "Submit",
        loading: "Submitting...",
      },
    };
  },
  methods: {
    async validForm(data: any) {
      const validatedForm: any = {};
      data.forEach((element: any) => {
        validatedForm[element.name] = element.value;
      });
      try {
        let resourceEndPoint = ApiResources.Contact.wrap("/");
        let result = await resourceEndPoint.post(validatedForm);
        this.callRestForm();
        this.displayMessage(
          result.data.message,
          result.status == 200 ? "white" : "warning"
        );
      } catch (err: any) {
        this.callRestForm(false);
        this.displayMessage(err.response.data.message, "danger");
      }
    },
    callRestForm(reset = true) {
      (this.$refs["generatedform"] as any).resetForm(reset);
    },
  },
});
