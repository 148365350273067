<template>
  <div class="services">
    <div class="container">
      <div class="section-title">
        <h2>Services</h2>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
          <div class="icon-box">
            <div class="icon"><i class="fa-solid fa-compass-drafting"></i></div>
            <h4>Plan & Design</h4>
            <p>
              We provide suitable 3D design and plan to meet your construction
              requirements.
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
          <div class="icon-box">
            <div class="icon"><i class="fa-solid fa-hotel"></i></div>
            <h4>Commercial Buildings</h4>
            <p>
              We have multiple services and products for Commercial Buildings to
              bring modren look and feel.
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
          <div class="icon-box">
            <div class="icon"><i class="fa-solid fa-city"></i></div>
            <h4>Residential Buildings</h4>
            <p>
              We also provide our graceful services for Residential Buildings to
              make your dream come true.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
