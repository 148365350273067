
import { defineComponent } from "vue";
import { RestResources } from "../../rest-resource";
const ApiResources = RestResources();

export default defineComponent({
  data() {
    return {
      submitted: false,
      button: "Call to Action",
      form: {
        name: "",
        contact_number: "",
      },
    };
  },
  computed: {
    inValidPhone() {
      return !/^[6-9]\d{9}$/gi.test(this.form.contact_number);
    },
  },
  methods: {
    async submit() {
      this.submitted = true;
      if (!this.form.name || !this.form.contact_number) {
        return;
      }
      this.button = "Submitting";

      try {
        let resourceEndPoint = ApiResources.Cta.wrap("/");
        let result = await resourceEndPoint.post(this.form);
        this.displayMessage(
          result.data.message,
          result.status == 200 ? "white" : "warning"
        );
        this.button = "Call to Action";
        this.submitted = false;
        if (result.status == 200) {
          this.form.name = "";
          this.form.contact_number = "";
        }
      } catch (err: any) {
        this.submitted = false;
        this.button = "Call to Action";
        this.displayMessage(err.response.data.message, "danger");
      }
    },
  },
});
