
import { defineComponent } from "vue";
import About from "./About.vue";
import Contact from "./Contact.vue";
import Services from "./Services.vue";
import Products from "./Products.vue";
import Cta from "./Cta.vue";
import { RestResources } from "../../rest-resource";
const ApiResources = RestResources();
export default defineComponent({
  name: "Home",
  components: {
    About,
    Services,
    Contact,
    // Header,
    Products,
    Cta,
  },
  data() {
    return {
      scrollPosition: 0,
      refName: "",
      categories: [],
    };
  },
  created() {
    this.emitter.on("scrollToElement", (refName: any) => {
      this.refName = refName;
      this.scrollToRef(refName);
    });
  },
  methods: {
    select(el: any, all = false) {
      el = el.trim();
      if (all) {
        return [...document.querySelectorAll(el)];
      } else {
        return document.querySelector(el);
      }
    },
    /**
     * Scrolls to an element with header offset
     */
    scrollto(el: any) {
      let header = this.select("#header");
      let offset = header.offsetHeight;

      let elementPos = this.select(el).offsetTop;
      window.scrollTo({
        top: elementPos - offset,
        behavior: "smooth",
      });
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    /**
     * Navbar links active state on scroll
     */
    navbarlinksActive() {
      let navbarlinks = this.select("#navbar .scrollto", true);
      let position = window.scrollY + 200;
      navbarlinks.forEach((navbarlink: any) => {
        if (!navbarlink.hash) return;
        let section = this.select(navbarlink.hash);
        if (!section) return;
        if (
          position >= section.offsetTop &&
          position <= section.offsetTop + section.offsetHeight
        ) {
          navbarlink.classList.add("active");
        } else {
          navbarlink.classList.remove("active");
        }
      });
    },
    scrollToRef(refName: any) {
      let el: any = this.$refs[refName];
      if (el) {
        window.scrollTo({
          top: el.offsetTop - 90,
          behavior: "smooth",
        });
      }
    },
  },
  async beforeCreate() {
    let resourceEndPoint = ApiResources.ProductCategories.wrap("/all");
    let result = await resourceEndPoint.get();
    this.$nextTick(() => {
      this.categories = result.data;
    });
  },
  mounted() {
    this.scrollToRef(this.refName);
    window.addEventListener("scroll", this.updateScroll);
    window.addEventListener("load", this.navbarlinksActive);
    document.addEventListener("scroll", this.navbarlinksActive);
  },
});
