
import { defineComponent } from "vue";
export default defineComponent({
  name: "Header",
  props: {
    categories: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      activeImage: 0,
    };
  },
  methods: {
    getQuote() {
      this.$router.push("/get-quote");
    },
  },
});
